<template>
  <div
    ref="componentTopPoint"
    class="card-wrap"
    :class="{ 'single-card': single }"
  >
    <div v-if="cardData.collapsed" class="ticket-card-collapsed">
      <div v-if="ticketOrder.hasPassDiscount" class="pass-notification">
        <span>{{ $t("ticket_card_pass_applied") }}</span>
        <i class="material-icons">done</i>
      </div>
      <div class="event-date">{{ eventDate }}</div>
      <div class="event-name" ref="timeValidationPoint">
        {{ ticket.event.name }}
      </div>

      <div class="section-divider header-divider"></div>

      <div class="quantity-selector">
        <div class="ticket-info">
          <div class="ticket-name">{{ ticket.name }}</div>
          <div class="ticket-price">{{ ticketOrder.price | currency }}</div>
        </div>
        <div class="quantity-select">
          <select
            name="tickets quantity"
            id="tickets-quantity"
            v-model="ticket.accountTicketCard.quantity"
          >
            <option
              v-for="(item, index) in dropdownItems"
              :value="item.value"
              :key="index"
            >
              {{ item.title }}
            </option>
          </select>
          <span class="select-value">{{
            ticket.accountTicketCard.quantity
          }}</span>
          <i class="material-icons">arrow_drop_down</i>
        </div>
      </div>

      <template v-if="hasWaveTimes">
        <!-- time selector -->
        <div
          class="time-selector"
          :class="{ 'has-error': errors.timeSelector }"
        >
          <div class="selector-controls">
            <div class="title-side">
              <i class="material-icons">schedule</i>
              <div>{{ $t("wave_time_block_start") }}</div>
            </div>
            <div class="buttons-side" v-if="waveTimes && waveTimes.length > 0">
              <div
                class="carousel-button"
                :class="{
                  'is-disabled': waveTimeCarousel.currentScrollPos === 0,
                }"
                @click="moveCarousel('left')"
              >
                <i class="material-icons">chevron_left</i>
              </div>
              <div
                class="carousel-button"
                :class="{
                  'is-disabled':
                    waveTimeCarousel.currentScrollPos ===
                    waveTimeCarousel.maxScrollPos,
                }"
                @click="moveCarousel('right')"
              >
                <i class="material-icons">chevron_right</i>
              </div>
            </div>
          </div>
          <template>
            <div
              class="time-carousel"
              :id="`time-carousel-${ticket.id}`"
              v-if="waveTimes && waveTimes.length > 0"
            >
              <div
                class="carousel-item"
                :class="{
                  'is-selected':
                    selectedWaveTime &&
                    wave.waveTimeId === selectedWaveTime.waveTimeId,
                  'is-disabled':
                    ticket.accountTicketCard.quantity > wave.remainingQuantity,
                }"
                v-for="(wave, index) in waveTimes"
                :key="index"
                @click="setWaveTime(wave)"
              >
                <span class="item-title">{{ wave.description }}</span>
                <span class="item-subline"
                  >{{ wave.remainingQuantity }}
                  {{ $t("wave_time_block_spots") }}</span
                >
              </div>
            </div>
            <div v-else class="sold-out-block">
              <div>{{ $t("event_card_sold_out") }}</div>
            </div>
          </template>
        </div>

        <!-- time selector (mobile) -->
        <div class="time-modal">
          <v-dialog
            class="mem-dialog"
            :max-width="410"
            overlay-color="#000000"
            overlay-opacity="0.54"
            transition="slide-y-reverse-transition"
            v-model="timeModal"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="modal-activator"
                :class="{
                  'is-disabled': !waveTimes || waveTimes.length === 0,
                  'has-value': selectedWaveTime,
                  'has-error': errors.timeSelector,
                }"
              >
                <template>
                  <span v-if="!waveTimes || waveTimes.length === 0">{{
                    $t("event_card_sold_out")
                  }}</span>
                  <template v-else>
                    <template>
                      <span v-if="selectedWaveTime">{{
                        selectedWaveTime.description
                      }}</span>
                      <span v-else>{{ $t("wave_time_block_start") }}</span>
                    </template>
                    <i class="material-icons">arrow_drop_down</i>
                  </template>
                </template>
              </div>
            </template>
            <div class="modal-body">
              <div
                class="list-item"
                :class="{
                  'is-disabled':
                    ticket.accountTicketCard.quantity > wave.remainingQuantity,
                }"
                v-for="(wave, index) in waveTimes"
                :key="index"
                @click="setWaveTime(wave)"
              >
                <span class="item-title">{{ wave.description }}</span>
                <span class="item-subline"
                  >{{ wave.remainingQuantity }}
                  {{ $t("wave_time_block_spots") }}</span
                >
              </div>
            </div>
          </v-dialog>
        </div>
      </template>

      <div
        class="section-no-wave-time"
        v-if="!hasWaveTimes && ticket.remainingQuantity === 0"
      >
        <div class="sold-out-block">
          <span>{{ $t("event_card_sold_out") }}</span>
        </div>
        <div class="sold-out-block-mobile">
          <span>{{ $t("event_card_sold_out") }}</span>
        </div>
      </div>

      <!-- Racer information form -->
      <template v-if="showRacerInfoForm">
        <div class="section-divider"></div>
        <div class="form-title" ref="racerInformation">
          {{ $t("racer_information_title") }}
        </div>
        <v-form ref="racerInfoForm" class="racer-form">
          <v-text-field
            light
            disabled
            :color="textFieldColor"
            class="form-field"
            v-model.trim="ticket.accountTicketCard.user.firstName"
            :label="$t('first_name')"
          ></v-text-field>
          <v-text-field
            light
            disabled
            :color="textFieldColor"
            class="form-field"
            v-model.trim="ticket.accountTicketCard.user.lastName"
            :label="$t('last_name')"
          ></v-text-field>
          <v-text-field
            light
            disabled
            :color="textFieldColor"
            class="form-field"
            v-model.trim="ticket.accountTicketCard.user.email"
            :label="$t('email')"
          ></v-text-field>
          <mem-phone-field
            light
            class="form-field"
            v-model="ticket.accountTicketCard.user.mobilePhone"
            :label="$t('phone_number')"
            :validate-field="validatePhoneField"
            :phone-data="ticket.accountTicketCard.user.mobilePhoneParsed"
            :phone-country="userData.spartan_country_code"
          ></mem-phone-field>
          <v-select
            light
            :color="textFieldColor"
            class="form-field form-select"
            v-model="ticket.accountTicketCard.user.gender"
            :items="genders"
            item-text="full"
            item-value="short"
            :label="$t('gender')"
            append-icon="keyboard_arrow_down"
            :rules="[(v) => !!v || $t('field_required')]"
          ></v-select>
          <mem-date-picker
            light
            :color="textFieldColor"
            class="form-field"
            v-model="ticket.accountTicketCard.user.birthDate"
            label="birth_date"
            :rules="birthDateRules"
            is-birthday
          />
        </v-form>

        <div class="form-title" ref="shippingAddress">
          {{ $t("shipping_address_form_title") }}
        </div>
        <address-form
          class="shipping-address-form"
          :address="ticket.accountTicketCard.attendeeAddress"
          :validate="validateAddressForm"
        />
      </template>

      <!-- customer questions list -->
      <customer-questions
        v-if="ticket.customerQuestions.length !== 0"
        :questionsList="ticket.customerQuestions"
        :validateList="errors.customerQuestions"
      />

      <!-- Addons -->
      <template v-if="showAddons">
        <div class="section-divider"></div>
        <addons-list
          :addonQuestions="ticket.addonQuestions"
          :addonComplexQuestions="ticket.addonComplexQuestions"
          :addonsList="ticket.addonsList"
          :maxQuantity="ticket.accountTicketCard.quantity"
          @list-change="$emit('addons-list-change')"
        />
        <!-- <div class="section-divider"></div> -->
      </template>

      <!-- Signature -->
      <template v-if="!ticket.addonTicket">
        <div class="section-divider" ref="waiverValidationPoint"></div>
        <div class="waiver-section">
          <div class="section-top">
            <div class="section-title">{{ $t("waiver_terms_waiver") }}</div>
            <v-dialog
              v-model="termsModal"
              class="mem-dialog"
              overlay-color="#000000"
              overlay-opacity="0.54"
              :max-width="452"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="terms-modal-button">
                  {{ $t("waiver_terms_read") }}
                </div>
              </template>
              <div class="terms-modal">
                <div class="close-button" @click="termsModal = false">
                  <i class="material-icons">close</i>
                </div>
                <div
                  class="terms-modal-body"
                  v-if="ticket.waiver && ticket.waiver.text"
                  v-html="ticket.waiver.text"
                ></div>
              </div>
            </v-dialog>
          </div>
          <div
            class="signature-wrap"
            :class="{ 'has-error': errors.waiverSignature }"
          >
            <VueSignature
              ref="signature"
              :sigOption="signatureOption"
              @beginStroke="signatureBeginStroke"
              @endStroke="signatureEndStroke"
            />
            <div class="signature-label">
              <span>{{ $t("waiver_terms_add_signature") }}</span>
            </div>
            <div class="undo-button" @click="signatureUndo">
              <i class="material-icons">undo</i>
              <span>{{ $t("waiver_terms_undo_signature") }}</span>
            </div>
          </div>
          <div class="waiver-terms" @click="waiverTerms = !waiverTerms">
            <mem-checkbox
              v-model="waiverTerms"
              theme="light"
              :has-error="errors.waiverTerms"
            />
            <span
              class="terms-text"
              :class="{ 'has-error': errors.waiverTerms }"
              >{{ $t("waiver_terms_check") }}</span
            >
          </div>
        </div>
      </template>

      <div class="collapse-button" v-if="!single">
        <mem-button btn-type="secondary-light" @click="submitCard">{{
          $t("save")
        }}</mem-button>
      </div>

      <!-- Remove ticket modal -->
      <v-dialog
        v-model="removeTicketModal"
        class="mem-dialog"
        :max-width="375"
        overlay-color="#000000"
        overlay-opacity="0.54"
      >
        <div class="remove-modal">
          <div>{{ $t("remove-ticket-modal-message") }}</div>
          <div class="modal-buttons">
            <mem-button @click="cancelRemove" btnType="tertiary-light">{{
              $t("remove_ticket_modal_cancel")
            }}</mem-button>
            <mem-button btnType="secondary-light" @click="deleteCard">{{
              $t("remove_ticket_modal_remove")
            }}</mem-button>
          </div>
        </div>
      </v-dialog>
    </div>
    <div v-else class="ticket-card">
      <div class="card-info">
        <div>{{ eventDate }}</div>
        <div class="ticket-data">
          <div v-if="ticketOrder.hasPassDiscount" class="pass-notification">
            <span>{{ $t("ticket_card_pass_applied") }}</span>
          </div>
          <span class="card-quantity">{{
            ticket.accountTicketCard.quantity
          }}</span>
          <template>
            <span v-if="ticket.accountTicketCard.quantity === 1">{{
              $t("ticket")
            }}</span>
            <span v-else>{{ $t("tickets") }}</span>
          </template>
        </div>
      </div>
      <div class="card-title">
        {{ ticket.event.name }}
      </div>
      <div class="card-controls">
        <div class="wave-details">
          <div class="wave-name">{{ ticketTypeName }}</div>
          <div
            v-if="selectedWaveTime"
            class="wave-time"
            v-text="selectedWaveTime.description"
          />
        </div>
        <div class="card-buttons">
          <div v-if="cardData.completed" class="completed-state">
            <div class="text-status">
              <i class="material-icons">done</i>
              <span>{{ $t("completed") }}</span>
            </div>
            <mem-button
              btn-type="tertiary-light"
              @click="$emit('edit-card', ticket)"
              >{{ $t("edit") }}</mem-button
            >
          </div>
          <mem-button v-else btn-type="secondary-light" @click="collapseCard">{{
            $t("event_card_add_details")
          }}</mem-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// https://www.npmjs.com/package/vue-signature
// Probably replace with https://www.npmjs.com/package/vue-signature-pad
import VueSignature from "vue-signature";
import parsePhoneNumber from "libphonenumber-js";
import moment from "moment";
import {
  filter,
  find,
  orderBy,
  toNumber,
  some,
  isEmpty,
  lowerCase,
} from "lodash";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "mem-checkbox": () => import("@/components/base/BaseCheckbox.vue"),
    "customer-questions": () => import("./CustomerQuestionsList.vue"),
    "addons-list": () => import("./AddonsList.vue"),
    "mem-phone-field": () => import("@/components/base/BasePhoneFieldV2.vue"),
    "mem-date-picker": () => import("@/components/base/BaseDatePicker.vue"),
    "address-form": () => import("./AddressForm.vue"),
    VueSignature,
  },
  data: () => ({
    ticketsOldQuantity: 0,
    selectedWaveTime: null,
    waiverSignature: null,
    waiverTerms: false,
    errors: {
      timeSelector: false,
      waiverSignature: false,
      waiverTerms: false,
      customerQuestions: false,
    },

    signatureOption: {
      backgroundColor: "rgba(34, 34, 34, 0.05)",
    },
    quantityDropdown: null,
    timeModal: null,
    termsModal: null,
    removeTicketModal: null,
    waveTimeCarousel: {
      currentScrollPos: 0,
      maxScrollPos: 0,
      scollStep: 0,
    },
    athleteAge: 0,
    racerFormErrors: {},
    validatePhoneField: false,
    validateAddressForm: false,
    textFieldColor: "#8D8D8D",
  }),
  computed: {
    ...mapGetters([
      "orderDetails",
      "checkoutParams",
      "storedTickets",
      "isLoadedStatus",
      "userData",
    ]),
    genders() {
      return [
        { full: this.$t("g_male"), short: "M" },
        { full: this.$t("g_female"), short: "F" },
      ];
    },
    birthDateRules() {
      const rules = [(val) => !!val || this.$t("field_required")];
      const minAge = toNumber(this.ticket?.minimumAge);
      const maxAge = toNumber(this.ticket?.maximumAge);

      rules.push(() => {
        if (this.athleteAge < minAge)
          return this.$t("ticket_minimum_age", {
            age: minAge,
          });
        if (this.athleteAge > maxAge)
          return this.$t("ticket_maximum_age", {
            age: maxAge,
          });
        return true;
      });

      return rules;
    },
    showRacerInfoForm() {
      let { tags } = this.ticket;
      if (!tags) return;
      // T-PASS, NT-PASS
      return tags.includes("T-PASS") || tags.includes("NT-PASS");
    },
    racerInfoFilled() {
      // form not exist
      if (!this.showRacerInfoForm) return true;

      // validate b-date
      const minAge = toNumber(this.ticket?.minimumAge);
      const maxAge = toNumber(this.ticket?.maximumAge);
      if (this.athleteAge > maxAge || this.athleteAge < minAge) return false;

      // validate phone number
      let racerPhone = parsePhoneNumber(`+${this.cardData.user.mobilePhone}`);
      if (!racerPhone || !racerPhone.isValid()) return false;

      // validate fields fill
      let { mobilePhone, gender, birthDate } = this.cardData.user;
      let requiredFields = { mobilePhone, gender, birthDate };

      return !some(requiredFields, isEmpty);
    },
    shippingAddressFilled() {
      // check TS settings config
      if (!this.showRacerInfoForm) return true;

      let { address, country, city, zip } =
        this.ticket.accountTicketCard.attendeeAddress;
      let requiredFields;
      // if (this.statesList)
      //   requiredFields = { address, country, state, city, zip };
      requiredFields = { address, country, city, zip };

      return !some(requiredFields, isEmpty);
    },
    customerQuestionsFilled() {
      let requiredQuestion = find(
        this.ticket.customerQuestions,
        (question) => question.required && !question.answerText
      );
      if (!requiredQuestion) return true;
      return false;
    },
    eventDate() {
      let { passTicket, event } = this.ticket;

      if (passTicket)
        return `${moment(event?.starts).format("MMM DD, YYYY")} - ${moment(
          event?.ends
        ).format("MMM DD, YYYY")}`;

      return moment(event?.starts).format("MMM DD, YYYY");
    },
    ticketTypeName() {
      let { name } = this.ticket;
      if (!name) return;

      if (name.indexOf("(") !== -1)
        return lowerCase(name.substr(0, name.indexOf("(")));
      return lowerCase(name);
    },
    dropdownItems() {
      let list = [];

      let maxQuantity = 30;
      if (this.ticket.remainingQuantity && this.ticket.remainingQuantity < 31)
        maxQuantity = this.ticket.remainingQuantity;
      // NT-PASS (only one is possible)
      if (this.ticket.tags.includes("NT-PASS")) maxQuantity = 1;

      for (let quantity = 0; quantity <= maxQuantity; quantity++) {
        let item = {};
        item.value = quantity;

        let discountLevel = find(
          this.ticket.bulk_pricing?.discountLevels,
          (level) => {
            let min = +level.min;
            let max = +level.max;

            if (max === 0) max = 30;
            return quantity >= min && quantity <= max;
          }
        );

        if (discountLevel)
          item.title = `${quantity} (${Math.round(
            +discountLevel.discount
          )}% off)`;
        else item.title = `${quantity}`;

        list.push(item);
      }
      return list;
    },
    showAddons() {
      let { addonTicket, addonsList, addonQuestions } = this.ticket;
      if (addonTicket) return;
      if (
        addonsList &&
        addonsList.length === 0 &&
        addonQuestions &&
        addonQuestions.length === 0
      )
        return;

      return true;
    },
    hasWaveTimes() {
      return this.ticket.waveTimes?.length > 0;
    },
    waveTimes() {
      let availableWaves = orderBy(
        filter(
          this.ticket.waveTimes,
          (waveTime) => waveTime.remainingQuantity > 0
        ),
        ["startTimestamp"]
      );
      return availableWaves;
    },
    ticketOrder() {
      let { tickets } = this.orderDetails;
      let ticket = find(tickets, { type_id: +this.ticket.id });
      // if (ticket) return ticket.amount;
      // return this.ticket.price;

      return {
        price: ticket ? ticket.amount : this.ticket.price,
        hasPassDiscount: ticket && ticket.passData ? true : false,
      };
    },
    cardData() {
      return this.ticket.accountTicketCard;
    },
  },
  props: {
    // TODO: delete country prop
    country: String,
    ticket: {
      type: Object,
      default: () => ({}),
    },
    validate: {
      type: Boolean,
      default: false,
    },
    single: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    validate: {
      handler(val) {
        if (!val) return;
        if (this.cardData.collapsed) this.submitCard();
      },
    },
    waiverTerms: {
      handler() {
        this.errors.waiverTerms = false;
      },
    },
    "cardData.quantity": {
      async handler(quantity, oldQuantity) {
        if (quantity === 0) {
          this.ticketsOldQuantity = oldQuantity;
          this.removeTicketModal = true;
          return;
        }
        // cancel remove ticket
        if (oldQuantity === 0) return;

        await this.changeTicketsQuantity(quantity);
      },
    },
    "cardData.collapsed": {
      handler(val) {
        if (!val) return;
        if (this.cardData.signature) {
          this.prefillSignature();
        }
        if (this.validate) {
          setTimeout(() => {
            this.submitCard();
          }, 600);
        }
        this.$nextTick(() => {
          this.configureCarousell();
          let carouselEl = document.getElementById(
            `time-carousel-${this.ticket.id}`
          );
          carouselEl?.addEventListener("scroll", () => {
            this.configureCarousell();
          });
        });
      },
    },
    "cardData.user.birthDate": {
      immediate: true,
      handler(date) {
        if (!date) return;

        this.ticket.accountTicketCard.user.birthDateParsed.month = toNumber(
          date.split("-")[1]
        );
        this.ticket.accountTicketCard.user.birthDateParsed.day = toNumber(
          date.split("-")[2]
        );
        this.ticket.accountTicketCard.user.birthDateParsed.year = toNumber(
          date.split("-")[0]
        );

        this.athleteAge = moment().diff(moment(date), "year");
      },
    },
    "cardData.user.mobilePhone": {
      immediate: true,
      handler(phone) {
        if (!phone) return;

        let parseData = parsePhoneNumber(`+${phone}`);
        if (!parseData.isValid()) return;

        this.ticket.accountTicketCard.user.mobilePhoneParsed = {
          code: parseData.countryCallingCode,
          country: parseData.country,
          number: parseData.nationalNumber,
        };
      },
    },
  },
  methods: {
    ...mapActions(["updateTicketsListItem"]),
    ...mapMutations(["updateTicketCard"]),
    signatureUndo() {
      this.$refs.signature.undo();
    },
    signatureBeginStroke() {
      // Clear saved data
      this.updateTicketCard({
        ticketTypeId: this.ticket.id,
        field: "signature",
        value: "",
      });
    },
    async signatureEndStroke(event) {
      console.log("Saving new signature...", event);
      await this.signatureSave();
    },
    async signatureSave() {
      let signatureIsEmpty = this.$refs.signature.isEmpty();
      if (signatureIsEmpty) return;

      if (!this.cardData.signature) {
        let signatureImg = this.$refs.signature.save("image/svg+xml");
        let res = await fetch(signatureImg);
        let text = await res.text();

        this.updateTicketCard({
          ticketTypeId: this.ticket.id,
          field: "signature",
          value: text,
        });
        return signatureImg;
      }

      this.errors.waiverSignature = false;
    },
    prefillSignature() {
      // console.log("Prefill signature", this.cardData.signature);
      if (!this.cardData.signature) return;

      let url = `data:image/svg+xml;base64,${btoa(this.cardData.signature)}`;
      setTimeout(() => {
        this.$refs.signature.fromDataURL(url);
        this.waiverTerms = true;
      }, 0);
    },
    setWaveTime(wave) {
      this.errors.timeSelector = false;
      this.selectedWaveTime = wave;

      this.updateTicketCard({
        ticketTypeId: this.ticket.id,
        field: "waveTimeId",
        value: wave.waveTimeId,
      });

      if (this.timeModal) this.timeModal = false;
    },
    prefillWaveTime() {
      let savedWave = find(this.waveTimes, {
        waveTimeId: this.cardData.waveTimeId,
      });

      if (savedWave && savedWave.remainingQuantity > 0)
        this.setWaveTime(savedWave);

      if (
        this.waveTimes &&
        this.waveTimes.length === 1 &&
        this.waveTimes[0].remainingQuantity >=
          this.ticket.accountTicketCard.quantity
      ) {
        this.setWaveTime(this.waveTimes[0]);
      }
    },
    async changeTicketsQuantity(quantity) {
      this.removeTicketModal = false;
      let { eventId, id } = this.ticket;

      await this.updateTicketsListItem({
        ticket: {
          ts_event_id: toNumber(eventId),
          ts_ticket_type_id: toNumber(id),
          country: this.country.toUpperCase(),
        },
        quantity,
      });

      this.$emit("quantity-change", { ticket: this.ticket, quantity });

      if (!this.selectedWaveTime) return;

      if (this.selectedWaveTime.remainingQuantity < quantity)
        this.selectedWaveTime = null;
    },
    cancelRemove() {
      this.removeTicketModal = false;
      this.ticket.accountTicketCard.quantity = this.ticketsOldQuantity;
    },
    validateCard() {
      // check sold out status
      // tickets with wave time
      if (this.hasWaveTimes && (!this.waveTimes || this.waveTimes.length === 0))
        return console.log("All tickets with wave time are sold");
      // tickets without wave time
      if (!this.hasWaveTimes && this.ticket.remainingQuantity === 0)
        return console.log("All tickets are sold");

      // card validation
      console.log("Run validation");
      //
      if (this.showRacerInfoForm) {
        this.$refs.racerInfoForm.validate();
        this.validateAddressForm = !this.validateAddressForm;
        this.validatePhoneField = true;
        this.$nextTick(() => {
          this.validatePhoneField = false;
        });
      }

      // start time
      if (!this.selectedWaveTime && this.hasWaveTimes)
        this.errors.timeSelector = true;

      if (!this.ticket.addonTicket) {
        // waiver
        let signatureIsEmpty = this.$refs.signature.isEmpty();
        if (signatureIsEmpty) this.errors.waiverSignature = true;

        // waiver terms
        if (!this.waiverTerms) this.errors.waiverTerms = true;
      }

      // customer questions
      if (!this.customerQuestionsFilled) this.errors.customerQuestions = true;
      else this.errors.customerQuestions = false;

      // summary
      let { timeSelector, waiverSignature, waiverTerms, customerQuestions } =
        this.errors;

      if (
        timeSelector ||
        waiverSignature ||
        waiverTerms ||
        customerQuestions ||
        !this.racerInfoFilled ||
        !this.shippingAddressFilled
      ) {
        this.updateTicketCard({
          ticketTypeId: this.ticket.id,
          field: "valid",
          value: false,
        });
        this.$emit("end-validation");
        return this.scrollCard();
      }

      // all data filled, submit card
      this.$emit("submit-card", {
        ticket: this.ticket,
      });
    },
    configureCarousell() {
      let carouselEl = document.getElementById(
        `time-carousel-${this.ticket.id}`
      );
      if (!carouselEl) return;

      this.waveTimeCarousel.scollStep = carouselEl.offsetWidth;

      this.waveTimeCarousel.currentScrollPos = carouselEl.scrollLeft;

      this.waveTimeCarousel.maxScrollPos =
        carouselEl.scrollWidth - carouselEl.offsetWidth;
    },
    moveCarousel(direction) {
      if (direction === "right") {
        this.waveTimeCarousel.currentScrollPos =
          this.waveTimeCarousel.currentScrollPos +
          this.waveTimeCarousel.scollStep;
      }

      if (direction === "left") {
        this.waveTimeCarousel.currentScrollPos =
          this.waveTimeCarousel.currentScrollPos -
          this.waveTimeCarousel.scollStep;
      }

      this.$nextTick(() => {
        let { currentScrollPos, maxScrollPos } = this.waveTimeCarousel;
        let carouselEl = document.getElementById(
          `time-carousel-${this.ticket.id}`
        );

        if (currentScrollPos <= 0) {
          carouselEl.scrollLeft = 0;
          this.waveTimeCarousel.currentScrollPos = 0;
          this.configureCarousell();
          return;
        }
        if (currentScrollPos >= maxScrollPos) {
          carouselEl.scrollLeft = maxScrollPos;
          this.waveTimeCarousel.currentScrollPos = maxScrollPos;
          this.configureCarousell();
          return;
        }

        carouselEl.scrollLeft = currentScrollPos;
        this.configureCarousell();
      });
    },
    async submitCard() {
      if (!this.ticket.addonTicket) {
        this.waiverSignature = await this.signatureSave();
      }
      this.validateCard();
    },
    collapseCard() {
      this.$emit("edit-card", this.ticket);
      this.$nextTick(() => {
        this.$refs.componentTopPoint.scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    async deleteCard() {
      await this.changeTicketsQuantity(0);
      // delete card & cart addons

      // delete ticket
    },
    fillRacerForm() {
      this.ticket.accountTicketCard.user.firstName = this.userData.first_name;
      this.ticket.accountTicketCard.user.lastName = this.userData.last_name;
      this.ticket.accountTicketCard.user.email = this.userData.email;

      let cartTicket = find(this.storedTickets, {
        ts_ticket_type_id: this.cardData.id,
      });

      if (cartTicket && cartTicket.user) {
        let { user } = cartTicket;
        // phone
        this.ticket.accountTicketCard.user.mobilePhone = user.mobilePhone;
        this.ticket.accountTicketCard.user.mobilePhoneParsed =
          user.mobilePhoneParsed;
        //
        this.ticket.accountTicketCard.user.gender = user.gender || "";
        this.ticket.accountTicketCard.user.birthDate = user.birthDate;
        return;
      }

      // phone
      this.ticket.accountTicketCard.mobilePhone = this.userData.mobile_phone;
      this.ticket.accountTicketCard.user.mobilePhoneParsed =
        this.userData.mobile_phone_parsed;
      //
      this.ticket.accountTicketCard.user.gender = this.userData.gender || "";
      this.ticket.accountTicketCard.user.birthDate = this.userData.birth_date;
    },
    scrollCard() {
      const options = { behavior: "smooth" };
      let { timeSelector, waiverSignature, waiverTerms, customerQuestions } =
        this.errors;

      // timeValidationPoint
      // waiverValidationPoint
      // racerInformation
      // shippingAddress

      if (timeSelector || customerQuestions)
        return this.$refs.timeValidationPoint.scrollIntoView(options);

      if (!this.racerInfoFilled)
        return this.$refs.racerInformation.scrollIntoView(options);

      if (!this.shippingAddressFilled)
        return this.$refs.shippingAddress.scrollIntoView(options);

      if (waiverSignature || waiverTerms)
        return this.$refs.waiverValidationPoint.scrollIntoView(options);
    },
  },
  mounted() {
    // fill racer information form
    if (this.showRacerInfoForm) this.fillRacerForm();

    // auto select wave time
    this.prefillWaveTime();

    // fill signature (if sigle event in cart)
    if (this.cardData.signature && this.cardData.collapsed) {
      this.prefillSignature();
    }

    this.configureCarousell();
    let carouselEl = document.getElementById(`time-carousel-${this.ticket.id}`);
    carouselEl?.addEventListener("scroll", () => {
      this.configureCarousell();
    });
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;

@mixin soldOutBlock {
  height: 102px;
  border-radius: 10px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #cacac3;
}

.remove-modal {
  background-color: #ffffff;
  color: #000000;
  display: grid;
  grid-template-columns: 1fr;
  padding: 24px 22px;

  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  .modal-buttons {
    margin-top: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    button + button {
      margin-left: 8px;
    }
  }
}
.terms-modal {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d3d3d3;
  border-radius: 8px;

  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 24px;

  position: relative;
  .close-button {
    position: absolute;
    top: 10px;
    right: 8px;
    display: flex;
    @include cursorPointer;
    i {
      color: #d3d3d3;
    }
  }
  .terms-modal-body {
    font-size: 14px;
    line-height: 20px;
  }
}
.modal-body {
  background-color: #ffffff;
  color: #000000;
  padding: 16px;
  .list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    margin-bottom: 2px;
    &.is-disabled {
      background-color: #f4f3f3;
      border: none;
      color: #bbbbbb;
      pointer-events: none;
    }
    .item-title {
      font-size: 13px;
      font-weight: 700;
      line-height: 16px;
      margin-bottom: 4px;
      text-transform: uppercase;
    }
    .item-subline {
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.02em;
      color: #9d9d9d;
    }
  }
}
.dropdown-body {
  background: #ffffff;
  // border: 1px solid rgba(34, 34, 34, 0.2);
  // box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.18);
  // border-radius: 12px;
  display: flex;
  flex-direction: column;
  .list-item {
    color: #000000;
    padding: 12px 16px;
    @include cursorPointer;
  }
  .list-item:hover {
    background-color: #3e3e3e;
  }
}
.card-wrap {
  // &::after {
  //   content: " ";
  //   display: flex;
  //   height: 1px;
  //   margin-top: 40px;
  //   margin-bottom: 48px;
  //   background-color: #cfcfcf;
  //   @media screen and (max-width: $mobile-view) {
  //     display: none;
  //   }
  // }
}
.single-card {
  padding-bottom: 40px;
  @media screen and (max-width: $mobile-view) {
    padding-bottom: 0;
  }
}

.ticket-card-collapsed {
  display: flex;
  flex-direction: column;
  // margin-bottom: 40px;
  background-color: #ffffff;
  @media screen and (max-width: $mobile-view) {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    //
    padding-bottom: 16px;
    margin-bottom: 8px;
  }
  .section-divider {
    padding-top: 40px;
    padding-bottom: 40px;
    &::before {
      content: " ";
      display: flex;
      height: 1px;
      background-color: #cfcfcf;
    }
  }
  .header-divider {
    @media screen and (max-width: $mobile-view) {
      display: none;
    }
  }
  .pass-notification {
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin-bottom: 24px;
    color: #84bd00;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: -0.3px;
    text-transform: capitalize;
    i {
      font-size: 12px;
      margin-left: 8px;
    }
  }
  .event-date {
    font-size: 16px;
    line-height: 21px;
    margin-top: 24px;
    @media screen and (max-width: $mobile-view) {
      margin-top: 20px;
    }
  }
  .event-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-top: 8px;
    @media screen and (max-width: $mobile-view) {
    }
  }
  .quantity-selector {
    // margin-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    @media screen and (max-width: $mobile-view) {
      margin-top: 32px;
      // margin-bottom: 24px;
    }
    .ticket-info {
      .ticket-name {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;

        margin-bottom: 4px;
      }
      .ticket-price {
        font-size: 20px;
        line-height: 24px;
        @media screen and (max-width: $mobile-view) {
          font-size: 16px;
        }
      }
    }
    .quantity-select {
      height: 44px;
      width: 78px;
      border: 1px solid #d3d3d3;
      border-radius: 100px;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // justify-content: space-between;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;

      display: grid;
      grid-template-columns: 1fr;

      position: relative;
      select {
        outline: none;
        padding-left: 16px;
        padding-right: 8px;
        opacity: 0;
      }
      .select-value {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translate(0, -50%);
        pointer-events: none;
      }
      i {
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translate(0, -50%);
        pointer-events: none;
      }
    }
  }
  .time-selector {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    @media screen and (max-width: $mobile-view) {
      display: none;
    }
    .selector-controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-bottom: 18px;
      .title-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        i {
          font-size: 18px;
          margin-right: 12px;
        }
      }
      .buttons-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        .carousel-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 24px;
          width: 24px;

          border: 1px solid #e7e7e7;
          border-radius: 20px;
          @include cursorPointer;
          &.is-disabled {
            pointer-events: none;
            i {
              color: #cccccc;
            }
          }
        }
        .carousel-button + .carousel-button {
          margin-left: 8px;
        }
      }
    }
    .time-carousel {
      display: flex;
      overflow-y: auto;
      scroll-behavior: smooth;
      // padding-bottom: 20px;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      .carousel-item {
        min-width: 125px;
        height: 102px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        margin-right: 8px;
        border: 1px solid #cfcfcf;
        border-radius: 8px;
        padding-left: 10px;
        padding-right: 10px;

        @include cursorPointer;
        .item-title {
          font-size: 13px;
          font-weight: 700;
          line-height: 16px;
          margin-bottom: 4px;
          text-transform: uppercase;
        }
        .item-subline {
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.02em;
          color: #9d9d9d;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      .is-selected {
        border-color: #cc092f;
      }
      .is-disabled {
        background-color: #f4f3f3;
        border: none;
        color: #bbbbbb;
        pointer-events: none;
      }
    }
    .time-carousel::-webkit-scrollbar {
      display: none;
    }
    .sold-out-block {
      @include soldOutBlock;
    }
  }
  .time-modal {
    display: none;
    @media screen and (max-width: $mobile-view) {
      display: grid;
      margin-top: 24px;
    }
    .modal-activator {
      height: 44px;
      background-color: #f2f2f2;
      border-radius: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      text-transform: uppercase;
      &.is-disabled {
        pointer-events: none;
        color: #c8c8c2;
      }
      &.has-value {
        background-color: #ffffff;
        border: 1px solid #d3d3d3;
      }
      &.has-error {
        border: 1px solid #cc092f;
      }
    }
  }
  .section-no-wave-time {
    margin-top: 40px;
    @media screen and (max-width: $mobile-view) {
      margin-top: 24px;
    }
    .sold-out-block {
      @include soldOutBlock;
      @media screen and (max-width: $mobile-view) {
        display: none;
      }
    }
    .sold-out-block-mobile {
      display: none;
      @media screen and (max-width: $mobile-view) {
        height: 44px;
        background-color: #f2f2f2;
        border-radius: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        text-transform: uppercase;

        pointer-events: none;
        color: #c8c8c2;
      }
    }
  }
  .form-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 30px;
    text-transform: capitalize;
  }
  .racer-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 24px;
    @media screen and (max-width: $mobile-view) {
      grid-template-columns: 1fr;
    }
    ~ .form-title {
      margin-top: 20px;
    }
    .form-field {
      &.form-select {
        ::v-deep(.v-select__selection--comma) {
          margin-top: 6px;
          margin-bottom: 6px;
        }
      }
      ::v-deep(.v-label) {
        text-transform: capitalize;
        font-weight: 500;
        color: #8d8d8d;
      }
      &.v-input--is-disabled {
        ::v-deep(.v-input__slot::before) {
          border-image: repeating-linear-gradient(
              to right,
              rgba(216, 216, 216, 1) 0px,
              rgba(216, 216, 216, 1) 2px,
              transparent 2px
            )
            1 repeat;
        }
      }
    }
  }
  .waiver-section {
    .section-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      text-transform: capitalize;
      margin-bottom: 18px;
      .section-title {
        font-size: 18px;
        font-weight: 700;
      }
      .terms-modal-button {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .signature-wrap {
      height: 156px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      border: 1px solid rgba(34, 34, 34, 0.05);
      &.has-error {
        border-color: #ff0707;
      }
      .signature-label {
        pointer-events: none;
        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #a0a0a0;
        max-width: 86px;
      }
      .undo-button {
        position: absolute;
        bottom: 10px;
        right: 10px;

        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #bbbbbb;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include cursorPointer;
        i {
          font-size: 16px;
          margin-right: 4px;
        }
      }
    }
    .waiver-terms {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 26px;
      @include cursorPointer;
      .terms-text {
        margin-left: 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #646464;
        &.has-error {
          color: #ff0707;
        }
      }
    }
    // .has-error {
    //   border-color: #ff0707;
    // }
  }
  .collapse-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 46px;
    padding-bottom: 50px;
    button {
      height: 44px;
      padding: 0 24px;
      font-size: 14px;
      font-weight: 700;
    }
  }
  // errors
  .has-error {
    .selector-controls {
      .title-side {
        color: #ff0707;
      }
    }
    .time-carousel {
      .carousel-item {
        border-color: #ffd2d2;
      }
    }
  }
}

.ticket-card {
  background-color: #ffffff;
  min-height: 190px;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  // margin-bottom: 24px;
  margin-bottom: 40px;
  padding-top: 20px;
  padding-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: $mobile-view) {
    min-height: 244px;
    border: none;
    padding-top: 24px;
    border-radius: 0;
    margin-bottom: 8px;
  }
  .card-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;

    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-transform: capitalize;
    @media screen and (max-width: $mobile-view) {
      font-size: 14px;
      margin-bottom: 20px;
    }
    .ticket-data {
      display: flex;
      flex-direction: row;
      align-items: center;
      .pass-notification {
        display: flex;
        flex-direction: row;
        align-items: center;
        ::after {
          content: "•";
          margin-left: 6px;
          margin-right: 6px;
        }
      }
      .card-quantity {
        margin-right: 6px;
      }
    }
  }
  .card-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;

    max-width: 328px;
    flex: 1;
    @media screen and (max-width: $mobile-view) {
      letter-spacing: -0.5px;
      line-height: 32px;
    }
  }
  .card-controls {
    display: grid;
    grid-template-columns: minmax(0%, 100%) max-content;
    @media screen and (max-width: $mobile-view) {
      display: flex;
      flex-direction: column;
      align-items: normal;
      justify-content: flex-end;
    }

    .wave-details {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.3px;
      text-transform: capitalize;
      margin-right: 12px;

      .wave-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .wave-time {
        white-space: nowrap;
      }
      .wave-time::before {
        content: "•";
        margin-left: 4px;
        margin-right: 4px;
      }
    }
    .card-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @media screen and (max-width: $mobile-view) {
        margin-top: 20px;
      }
      .completed-state {
        display: flex;
        flex-direction: row;
        flex: 1;
        @media screen and (max-width: $mobile-view) {
          justify-content: space-between;
        }
        .text-status {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #84bd00;
          padding: 10px 16px;
          margin-right: 12px;
          @media screen and (max-width: $mobile-view) {
            margin-right: 0;
            padding: 10px 0;
          }
          i {
            font-size: 20px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>